import { models } from "powerbi-client";
  
  const layoutSettings = {

    layoutType: models.LayoutType.Custom,
    customLayout: {
      pageSize: null,
    displayOption: models.DisplayOption.FitToWidth ,
    pagesLayout: {
      ReportSection1: {
        defaultLayout: {
          displayState: {
            mode: models.VisualContainerDisplayMode.Hidden,
          },
        },
        visualsLayout: {
          VisualContainer1: {
            x: 1,
            y: 1,
            z: 1,
            width: 400,
            height: 300,
            displayState: {
              mode: models.VisualContainerDisplayMode.Visible,
            },
          },
          VisualContainer2: {
            displayState: {
              mode: models.VisualContainerDisplayMode.Visible,
            },
          },
        },
      },
    },
    },
  }
  export default layoutSettings