import React, { Component } from "react";

import { useState, useEffect } from "react";
import NavParent from "./NavParent";
import { Report } from "powerbi-report-component";
import { models } from "powerbi-client";
import IdleTimer from "react-idle-timer";
import { ThreeDots } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BeatLoader } from "react-spinners";


import layoutSettings from "./layoutSettings";
import {getAccessToken} from "./AccessToken";

import { getLogout, getMsalToken, getMsalUserInfo } from "./Msal";
import Axios from "axios";

const env = process.env.REACT_APP_ENV.toString();

export class Connectivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      embedToken: null,
      reportState: null,
      loading: true,
      access: false,
      username: null,
      lastname: null,
      role: null
    };
    this.timerRef = React.createRef()
    window.setInterval(()=>getMsalToken(), 10*60*1000);
    window.setInterval(()=>window.location.reload(),55*60*1000);
    window.setInterval(()=>getAccessToken(), 50*60*1000);
  }
  handleReportLoad = report => {
    this.setState({ reportState: report });
  };

  handleReportRender = report => {
    this.setState({ reportState: report });
  };

  getAccessAndEmbedToken = async () => {
    // const { accessTokenApi, powerBiRestApi, datasetId } = Connectivityconfig[
    //   env
    // ];
  const datasetId = process.env.REACT_APP_CONNECTIVITY_DATASETID.toString();
const reportId = process.env.REACT_APP_CONNECTIVITY_REPORTID.toString();
const groupID = process.env.REACT_APP_GROUPID.toString();

    let headers = {
      "Content-Type": "application/json",
      Authorization:
        localStorage.getItem("ACCESS_TOKEN") &&
        "Bearer " + localStorage.getItem("ACCESS_TOKEN")
    };
    let body = {
      accessLevel: "View",
      identities: [
        {
          username: getMsalUserInfo().username,
          roles: ["New role"],
          datasets: [datasetId]
        }
      ]
    };
    const powerBiRestApi = `https://api.powerbi.com/v1.0/myorg/groups/${groupID}/reports/${reportId}/GenerateToken`
    await Axios.post(powerBiRestApi, body, { headers: headers })
      .then(res => {
        this.setState({
          embedToken: res.data.token,
          loading: false,
          access: true
        });
        
      })
      .catch(err => console.log(err.response));
  };

  async componentDidMount() {
    this.getAccessAndEmbedToken();
    this.setState({ username: localStorage.getItem("USER_FIRSTNAME") });
    this.setState({ lastname: localStorage.getItem("USER_LASTNAME") });
    this.setState({ role: localStorage.getItem("ROLE") });
  }

  render() {
    // const { embedUrl, embedId } = Connectivityconfig[env];
    const embedId = process.env.REACT_APP_CONNECTIVITY_REPORTID.toString();
    const embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${embedId}&ctid=937d8a39-dd46-4ed8-802b-2c44da18c350&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`

    if (this.state.loading) {
      return (
        <div className="loader_style">
          <BeatLoader color="blue" size={30} loading />
        </div>
      );
    }
    return (
      <div>
        <NavParent
          username={this.state.username}
          role={this.state.role}
          lastname={this.state.lastname}
        />

        <Report
          embedType="report"
          tokenType="Embed"
          accessToken={this.state.embedToken}
          embedUrl={embedUrl}
          embedId={embedId}
          extraSettings={{
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            hideErrors: true,
            background: models.BackgroundType.Transparent,
            ...layoutSettings
          }}
          permissions="All"
          style={{ height: window.innerHeight, width: window.innerWidth }}
          onLoad={this.handleReportLoad}
          onRender={this.handleReportRender}
        />
        <IdleTimer ref={this.timerRef} timeout={45*60*1000} onIdle={()=>getLogout()} /> 
      </div>
    );
  }
}

export default Connectivity;
