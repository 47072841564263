import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ErrorMessage.css'
import { Container, Row, Col } from 'react-bootstrap';
import { getLogout } from '../Msal';


function ErrorMessage() {

    return ( 
    <div className = "BackImage" >
        <Container fluid>
            <Row>
                <Col xs = { 5 } sm = { 5 } md = { 5 } lg = { 5 } xl = { 5 } style = {{ position:'absolute', top:'40%', display:'flex', justifyContent:'space-around' } } >
                    <Container fluid >
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                            <h3 className='welcome-text-error'>401- Looks like you do not have access</h3>    
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='kpi-text-error mt-3 mb-5'>
                            <Container><Row>
                            <p>Your account is not authorised to view this page. Make</p>
                            <p>sure the URL is correct and your account has access.</p>
                            <p>You may request access by contacting the admin.</p>   
                            </Row></Container>
                            
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10} >
                            <a href="#" onClick={()=>getLogout()} className='btn-text-error' >  Login with a different account</a>
                            </Col>
                        </Row>
                    </Container>
                </Col> 
                
            </Row>
        </Container> 
    </div>
    )
}

export default ErrorMessage