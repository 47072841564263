import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./App.css";
import * as serviceWorker from './serviceWorker';
import { msalInstance } from './components/Msal';
import { MsalProvider } from '@azure/msal-react';
//import { stopReportingRuntimeErrors } from "react-error-overlay";

/* if (process.env.NODE_ENV === "development") {
  stopReportingRuntimeErrors(); // disables error overlays
} */



ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App/>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
