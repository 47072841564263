import { PublicClientApplication } from '@azure/msal-browser';
import Axios from 'axios';
const CLIENT_ID = process.env.REACT_APP_APPID.toString();

export let AuthToken;
 
const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: CLIENT_ID,
    postLogoutRedirectUri: window.location.protocol+ '//' + window.location.href.split('/')[2],
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  },
  //scopes: ['https://utcbisamer.onmicrosoft.com/d66e5f98-32a1-4666-8cf1-f6de793889b0']
};  

const loginRequest = {
  scopes: [
    'https://analysis.windows.net/powerbi/api/.default', "User.Read", "profile", "openid"
  ],
}
 
export const msalInstance = new PublicClientApplication(msalConfig);

export const getLogout = () => {
	setTimeout(()=> {
    msalInstance.logoutRedirect();
    localStorage.clear();	
  }, 1000);
};

export const runWithMsal = (app) => {
  if (window === window.parent) {
    msalInstance.handleRedirectPromise()
      .then((tokenRes) => {
        if (!tokenRes) {
          const account = msalInstance.getAllAccounts();
          if (account.length === 0) {
            msalInstance.loginRedirect();
          }
        }
        else {
          const { idToken } = tokenRes;
          localStorage.setItem("MSAL_TOKEN", idToken);
          AuthToken = idToken;
        }
        const account = msalInstance.getAllAccounts()[0];
        msalInstance.acquireTokenSilent({ loginRequest, account })
          .then(res => {
            AuthToken = res.idToken;
            localStorage.setItem("MSAL_TOKEN", res.idToken);
            Axios.defaults.headers.common['Authorization'] = res.idToken;
            app()
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e))
  }
}

export const getMsalToken = ()=>{
  const account = msalInstance.getAllAccounts()[0];
  msalInstance.handleRedirectPromise()
    .then(token=>{
      msalInstance.acquireTokenSilent({loginRequest, account})
        .then(res=>{
          const { idToken } = res;
          localStorage.setItem("MSAL_TOKEN", idToken);
          AuthToken = idToken;
        })
        .catch(e => console.log(e));
    })
}

export const getMsalRefreshedToken = ()=>{
  getMsalToken();
}

export const getMsalUserInfo = ()=>{
  const activeUser = msalInstance.getAllAccounts()[0] ? msalInstance.getAllAccounts()[0]: { profile: { 'name': "sd" } };
  return activeUser;
}

//Add the tenant Id here for successful login
export const validTenants = [
  "03afca2b-f47f-4d0b-9a25-d464aff5d351", //otiselevator tenant
  "9c7d751b-b1a4-4c4a-9343-12b2814ae031" ,//otis tenant
  "937d8a39-dd46-4ed8-802b-2c44da18c350",//Otis elevator tenant
  "26d923a9-6304-4745-8b2d-95793e9324e4", 
  "937d8a39-dd46-4ed8-802b-2c44da18c350",
  "03afca2b-f47f-4d0b-9a25-d464aff5d351",
  "f7cf6ab3-a154-48b5-b860-cfd99a40f9a8",
  "a384ed05-4d66-4ce7-937d-3dab0fb2d8d4",
  "2bae096f-a0e3-400c-8c0a-c0ac0a5e7fde",
  "9c7d751b-b1a4-4c4a-9343-12b2814ae031"
]
