import React, { Component } from 'react'
 
import{useState,useEffect} from 'react'
import NavParent from './NavParent'
import {Report} from 'powerbi-report-component';
import { models } from 'powerbi-client';
import IdleTimer from 'react-idle-timer';
import {ThreeDots} from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {BeatLoader} from 'react-spinners'

import{getLogout,getMsalToken,getMsalUserInfo} from './Msal'
import   Axios  from 'axios';


export class ROADashboard extends Component {

    constructor(props) {
        super(props)
         this.state = {
             accessToken: null,
             embedToken:null,
             reportState: null,
             loading: true,
             access: false,
             username:null,
             lastname:null
        }
    }
    handleReportLoad = (report)=>{
        this.setState({reportState:report})
    }
  
    handleReportRender = (report)=>{
        this.setState({reportState:report})
        
    }

    getAccessAndEmbedToken = async ()=>{
        const accessTokenApi = "https://afa-kpip-dev-api-naa-iilsblqj47dzi.azurewebsites.net/api/bearertoken?code=tVG7KQC5u1df3EspiqQ0Kfdvcg2PW8mzfY9RHfEqQyufk8o1k0CVlA=="
          await Axios.get(accessTokenApi).then((res)=>{
              this.setState({accessToken:res.data.headers.token})
              console.log(this.state.accessToken)
              }
          ).catch(err=>{
              console.log(err);
          })
          let headers = {
              'Content-Type' : 'application/json',
              'Authorization': this.state.accessToken && 'Bearer ' + this.state.accessToken
          }
          let body = {
            "accessLevel": "View",
            "identities": [
              {
                "username": getMsalUserInfo().username,
                "roles": [
                  "New role"
                ],
                "datasets": [
                    "e5fcee28-d912-4169-9a37-799498e420da"
                ]
              }
            ]
          }
          const powerBiRestApi ="https://api.powerbi.com/v1.0/myorg/groups/8dd3efa6-d056-496c-9431-21be58f69afc/reports/51ae6ed2-ca5e-40ef-ab55-410a2cb5e7f7/GenerateToken"
         await Axios.post(powerBiRestApi,body,{headers:headers}).then(res=>{
          this.setState({embedToken: res.data.token, loading: false, access: true})
          console.log(this.state.embedToken)
          }
          ).catch(err=>console.log(err.response) 
          )
      }
  
      async componentDidMount(){
        const accessAPI = `/api/ADMINPORTAL/${getMsalUserInfo().username}`
        const headers = {
          'Authorization':  'Bearer ' + localStorage.getItem("MSAL_TOKEN"),
        
          'Ocp-Apim-Subscription-Key': "8df96126ddec4283b2ceddcbf2fe63a8",
          'Access-Control-Allow-Origin':'http://localhost:3000/reports'
        }
        await Axios.get(accessAPI,{headers:headers}).then((res)=>{
          if (res.data.access.length) {
            this.getAccessAndEmbedToken();
            this.setState({username: res.data.profile.firstName,role:res.data.access[0].role.Description,
              lastname: res.data.profile.lastName})
            
          }
          else{
            this.setState({loading:false, access: false})
          }
        })
        .catch(e=>{
          console.log(e);
          this.setState({loading:false, access: false});
        })
        
      } 
      
    render() {

        const embedUrl = "https://app.powerbi.com/reportEmbed?reportId=51ae6ed2-ca5e-40ef-ab55-410a2cb5e7f7&ctid=937d8a39-dd46-4ed8-802b-2c44da18c350&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
      const embedId = "51ae6ed2-ca5e-40ef-ab55-410a2cb5e7f7"
        const layoutSettings = {
            layoutType: models.LayoutType.Custom,
            customLayout: {
              pageSize: null,
            displayOption: models.DisplayOption.FitToWidth ,
            pagesLayout: {
              ReportSection1: {
                defaultLayout: {
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Hidden,
                  },
                },
                visualsLayout: {
                  VisualContainer1: {
                    x: 1,
                    y: 1,
                    z: 1,
                    width: 400,
                    height: 300,
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                  VisualContainer2: {
                    displayState: {
                      mode: models.VisualContainerDisplayMode.Visible,
                    },
                  },
                },
              },
            },
            },
          }
          if(this.state.loading){
            return(
              <div className ='loader_style'>
              <BeatLoader color='blue' size ={30} loading/>
              </div>
            )
          } 
        return (
            <div>
                <NavParent username ={this.state.username} role ={this.state.role} lastname={this.state.lastname}/>
            
            <Report
                embedType="report"
                tokenType="Embed"
                accessToken= {this.state.embedToken}
                embedUrl={embedUrl}
                embedId={embedId}
                extraSettings={{
                    filterPaneEnabled: false, 
                    navContentPaneEnabled: false,
                    hideErrors: true, 
                    background: models.BackgroundType.Transparent,
                    ...layoutSettings
                    }}
                permissions="All" 
                style={
                    {height: window.innerHeight, width: window.innerWidth}
                }
                onLoad={this.handleReportLoad}
                onRender={this.handleReportRender}
                
                />

            
            
                
            </div>
        )
    }
}

export default ROADashboard