import Navbar from "./Navbar";
import KPI from "../Assets/KPI.svg";
import "./NavParent.css";
import React from "react";
import Capture from "../Assets/Capture.PNG";
import { getLogout } from "./Msal";
import { Avatar } from "evergreen-ui";
import Logout from "../Assets/Logout.png";

const NavParent = ({ username, role, lastname }) => {
  const user_avatar = username + " " + lastname;
  let role_description;
  if (role == "CM") {
    role_description = "Country Manager";
  } else {
    role_description = "Executive Manager";
  }

  return (
    <header>
      <div className="nav-area">
        <div className="left-aligned">
          <img src={KPI} className="logo"></img>
          <img src={Capture} className="logo-text"></img>
          <Navbar />
        </div>
        <div className="right-aligned">
          <div>
            <Avatar name={user_avatar} size={45}/>
          </div>
          <div className="userinfo">
            <span>
              {username.charAt(0).toUpperCase() +
                username.substr(1).toLowerCase()}{" "}
              {lastname.charAt(0).toUpperCase() +
                lastname.substr(1).toLowerCase()}
            </span>
            <span>{role_description}</span>
          </div>

          <div>
            <img onClick={getLogout} src={Logout} className="button_style" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavParent;
