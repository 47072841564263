import Axios from "axios";

export const getAccessToken = async () => {
    
    const accessTokenApi = process.env.REACT_APP_ACCESSTOKENAPI.toString();
    await Axios.get(accessTokenApi)
      .then(res => {
        
        localStorage.setItem("ACCESS_TOKEN", res.data.headers.token);
        console.log(res.data.headers.token);
      })
      .catch(err => {
        console.log(err);
      });


}
