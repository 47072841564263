import KPI from '../../Assets/KPI.svg'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'

import { Link } from 'react-router-dom';

import './HomeResponsive.css'
import { Container, Row, Col } from 'react-bootstrap'
function HomeResponsive() {
    return ( 
    <div className = "Background" >
        <Container fluid>
            <Row>
                <Col xs = {5} sm = { 5} md = { 5} lg = { 5} xl = {5 } style = {{ position:'absolute', top:'40%', display:'flex', justifyContent:'left' } } >
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='kpi-text'>
                            <img className='mb-3' style={{width:'9%', marginRight: '5px'}} src={KPI} alt='KPI Logo' />
                             <h1 style={{marginRight:"3px" ,marginLeft:"7px",fontSize:"46px",align:"marginLeft"}}>KPI DASHBOARD</h1> 
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                            <Link to='/reports'><Button className='btn-text' variant="outline-primary" >SIGN IN</Button>{' '}</Link>
                            </Col>
                        </Row>
                    </Container>
                </Col> 
            </Row>
        </Container> 
    </div>
    )
}
export default HomeResponsive
