import React from 'react'
import ReactDOM from "react-dom";

import{BrowserRouter,Route,Switch} from 'react-router-dom'
import HomeDashboard from './components/HomeDashboard'
import ROADashboard from './components/ROADashboard'
import ROADashboard2 from './components/ROADashboard2'

import ActiveMonitoring from './components/ActiveMonitoring'
import HomeResponsive from './components/HomePage/HomeResponsive'
import ActiveUsers from './components/ActiveUsers'
import ServiceVisits from './components/ServiceVisits'
import MechanicRecomendation from './components/MechanicRecomendation'
import{runWithMsal,msalInstance,validTenants} from './components/Msal'
import Connectivity from './components/Connectivity';
import ActivityHistory1 from './components/ActivityHistory1';
import ActivityHistory2 from './components/ActivityHistory2';
import ErrorMessage  from './components/ErrorPage/ErrorMessage'
import OxpAdoption from './components/OxpAdoption'
import Finance from './components/Finance';
import GuidedMaintanence from './components/GuidedMaintanence';
import GuidedCallback from './components/GuidedCallback';
import RemoteIntervention from './components/RemoteIntervention';



function App() {
  const ProtectedPowerBiComp = ()=>runWithMsal(()=>{
    const account = msalInstance.getAllAccounts()[0];
    const { idTokenClaims } = account;
    const { tid } = idTokenClaims;
    if(validTenants.some(id=>id === tid)){
      ReactDOM.render(<HomeDashboard/>, document.getElementById("root")) 
    }
    else 
    {
      ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
    }
  });
      const MsalROA = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<ROADashboard2/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));
      const MsalActiveMonitoring = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<ActiveMonitoring/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));

      const MsalMechanicRecomendation = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<MechanicRecomendation/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));

      const MsalActiveUsers = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<ActiveUsers/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));

      const MsalUnitChecks = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<ServiceVisits/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));

      const MsalConnectivity = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<Connectivity/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));
      const MsalActivityHistory1 = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
        const { tid } = idTokenClaims;
        const role = localStorage.getItem('ROLE')
        if(validTenants.some(id=>id === tid) && role=='EM'){
         
          ReactDOM.render(<ActivityHistory1/>, document.getElementById("root")) 
           
        }
        else
        {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
        }
        
      }));
      const MsalActivityHistory2 = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         const role = localStorage.getItem('ROLE')
         if(validTenants.some(id=>id === tid) && role=='EM'){
         
          ReactDOM.render(<ActivityHistory2/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));

      const MsalOXP = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<OxpAdoption/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));

      const MsalFinance = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<Finance/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));
      const MsalGuidedMaintenence = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<GuidedMaintanence/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));
      const MsalGuidedCallback = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<GuidedCallback/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));
      const MsalRemoteIntervention = ()=>(runWithMsal(()=>{
        const account = msalInstance.getAllAccounts()[0];
        const { idTokenClaims } = account;
         const { tid } = idTokenClaims;
         if(validTenants.some(id=>id === tid)){
         
          ReactDOM.render(<RemoteIntervention/>, document.getElementById("root")) 
           
        }
        else
         {
          ReactDOM.render(<ErrorMessage/>, document.getElementById("root")) 
         }
        
      }));

     
      

      
    
    return (
        <div style={{minHeight:'100vh'}}>
            
            <BrowserRouter>
           <Switch>
           <Route path='/'exact component={HomeResponsive} />
           <Route path='/reports' render={()=>ProtectedPowerBiComp()} /> 
           <Route path ='/ROA' render={()=>MsalROA()} />
           <Route path ='/ActiveMonitoring' render={()=>MsalActiveMonitoring()}/>
           <Route path ='/MechanicRecomendation' render={()=>MsalMechanicRecomendation()}/>
           <Route path ='/ActiveUsers' render={()=>MsalActiveUsers()}/>
           <Route path ='/Unitchecks'  render={()=>MsalUnitChecks()} />
           <Route path ='/Connectivity' render={()=>MsalConnectivity() }/>
           <Route path ='/Activity1' render={()=>MsalActivityHistory1() }/>
           <Route path ='/Activity2' render={()=>MsalActivityHistory2() }/>
           <Route path ='/OXP' render={()=>MsalOXP() }/>
           <Route path ='/finance' render={()=>MsalFinance() }/>
           <Route path ='/GuidedCallback' render={()=>MsalGuidedCallback() }/>
           <Route path ='/GuidedMaintenance' render={()=>MsalGuidedMaintenence () }/>
           <Route path ='/RemoteIntervention' render={()=>MsalRemoteIntervention () }/>
           </Switch>
            </BrowserRouter>

           
            
        </div>
    )
}

export default App