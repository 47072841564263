import {Link} from 'react-router-dom'
export const menuItems = [
  {
    title: "Home",
    url: "/reports",
    main_title:true
  },
  {
    title: "Business",
    main_title:true,
    submenu: [
      {
        title: "ROA",
        url: "/ROA",
      },
      {
        title: "Active Monitoring",
        url: "/ActiveMonitoring",
        
      },
      {
        title: "Remote Intervention",
        url: "/RemoteIntervention",
        
      },
      {
        title: "Informed Dispatch",
        submenu: [
          {
            title: "Mechanic Recomendation",
            url: "/MechanicRecomendation",
          },
          {
            title: "Guided Callback",
            url: "/GuidedCallback"
          },
          {
            title: "Guided Maintenence",
            url: "/GuidedMaintenance"
          },
        ],
      },
      {
        title: "OOV User Adoption",
        
        submenu: [
          {
            title: "Active Users",
            url: "/ActiveUsers",
          },
          {
            title: "Unit Checks(Service visit)",
            url: "/Unitchecks"
          },
          
        ],
      } ,
      {
        title: "OXP Adoption",
        url: "/OXP",
      },

    ]
  },
  {
    title: "Operations",
    main_title:true,
    submenu: [
      {
        title: "Connectivity",
        url: "/Connectivity",
      },
    ]
  },
  {
    title :"Finance",
    url : "/finance",
    main_title:true

  },
  {
    title:'Activity History',
    main_title:true,
    submenu: [
      {
        title: "Activity History Operational",
        url: "/Activity1",
      },
      {
        title: "Activity History Field Productivity",
        url: "/Activity2"
      },
      
    ],
  }
]