import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import React from "react";

import Dropdown from "./Dropdown";
import Dropdowncustom from "./Dropdowncustom";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = event => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <div>
      <li
        className="menu-items"
        ref={ref}
        // onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items.url && items.submenu ? (
          <>
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              //  onClick={() => setDropdown((prev) => !prev)}
            >
              <a href={items.url}>{items.title}</a>
              {/* {items.title}{" "} */}
              {depthLevel > 0 ? (
                <span className="arrow_bracket">&raquo;</span>
              ) : (
                <span
                  className={`arrow${
                    items.url && items.submenu ? " custom" : ""
                  }`}
                />
              )}
            </button>

            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </>
        ) : !items.url && items.submenu ? (
          items.title == "Activity History" &&
          (localStorage.getItem("ROLE") == "CM")?null:
          (
          <>
              <button
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => setDropdown(prev => !prev)}
                className="button_class"
              >
                {/* <a href="/#">{items.title}</a> */}
                {items.main_title ? (
                  <div style={{ color: "white", fontWeight: "bold" }}>
                    {items.title}{" "}
                  </div>
                ) : (
                  <div style={{ color: "black" }}>{items.title} </div>
                )}

                {depthLevel > 0 ? (
                  <span className="arrow_bracket_ID">&raquo;</span>
                ) : (
                  <span className="arrow" />
                )}
              </button>
              {items.title == "Activity History" ? (
                <Dropdowncustom
                  depthLevel={depthLevel}
                  submenus={items.submenu}
                  dropdown={dropdown}
                />
              ) : (
                <Dropdown
                  depthLevel={depthLevel}
                  submenus={items.submenu}
                  dropdown={dropdown}
                />
              )}

              {/* <Dropdown
                depthLevel={depthLevel}
                submenus={items.submenu}
                dropdown={dropdown}
              /> */}
            </>
            )
          
        ) : items.main_title ? (
          window.location.pathname == items.url ? (
            <a
              style={{
                color: "white",
                fontWeight: "bold",
                pointerEvents: "none"
              }}
              href={items.url}
            >
              {items.title}
            </a>
          ) : (
            <a style={{ color: "white", fontWeight: "bold" }} href={items.url}>
              {items.title}
            </a>
          )
        ) : window.location.pathname == items.url ? (
          <a style={{ color: "black", pointerEvents: "none" }} href={items.url}>
            {items.title}
          </a>
        ) : (
          <a style={{ color: "black" }} href={items.url}>
            {items.title}
          </a>
        )}
      </li>
    </div>
  );
};

export default MenuItems;
