import React, { Component } from "react";

import { useState, useEffect } from "react";
import NavParent from "./NavParent";
import { Report } from "powerbi-report-component";
import { models } from "powerbi-client";
import IdleTimer from "react-idle-timer";

import { BeatLoader } from "react-spinners";
import layoutSettings from './layoutSettings'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getLogout, getMsalToken, getMsalUserInfo } from "./Msal";
import Axios from "axios";
import { OptionShapePropType } from "evergreen-ui";
import { flex } from "ui-box";
import {getAccessToken} from "./AccessToken";
import ErrorMessage from "./ErrorPage/ErrorMessage";

const env = process.env.REACT_APP_ENV.toString();

export class HomeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      embedToken: null,
      reportState: null,
      loading: true,
      access: false,
      username: null,
      role: null,
      lastname: null
    };
    this.timerRef = React.createRef()
    window.setInterval(()=>getMsalToken(), 10*60*1000);
    window.setInterval(()=>window.location.reload(),55*60*1000);
    window.setInterval(()=>getAccessToken(), 50*60*1000);
    
  }
  handleReportLoad = report => {
    this.setState({ reportState: report });
  };

  handleReportRender = report => {
    this.setState({ reportState: report });
  };

  getAccessAndEmbedToken = async () => {
    // const { accessTokenApi, powerBiRestApi, datasetId } = Homeconfig[env];
    const accessTokenApi = process.env.REACT_APP_ACCESSTOKENAPI.toString();
    const datasetId = process.env.REACT_APP_HOME_DATASETID.toString();
    const reportId = process.env.REACT_APP_HOME_REPORTID.toString();
    const groupID = process.env.REACT_APP_GROUPID.toString();
    
    await Axios.get(accessTokenApi)
      .then(res => {
        this.setState({ accessToken: res.data.headers.token });
        localStorage.setItem("ACCESS_TOKEN", res.data.headers.token);
        
      })
      .catch(err => {
        console.log(err);
      });
    let headers = {
      "Content-Type": "application/json",
      Authorization:
        this.state.accessToken && "Bearer " + this.state.accessToken
    };
    let body = {
      accessLevel: "View",
      identities: [
        {
          username: getMsalUserInfo().username,
          roles: ["New role"],
          datasets: [datasetId]
        }
      ]
    };
    const powerBiRestApi = `https://api.powerbi.com/v1.0/myorg/groups/${groupID}/reports/${reportId}/GenerateToken`
    await Axios.post(powerBiRestApi, body, { headers: headers })
      .then(res => {
        this.setState({
          embedToken: res.data.token,
          loading: false,
          access: true
        });
      })
      .catch(err => console.log(err.response));
  };

  async componentDidMount() {
    if (
      localStorage.getItem("USER_FIRSTNAME")!=null &
      localStorage.getItem("USER_LASTNAME")!=null &
      localStorage.getItem("ROLE")!=null
    ) {
      this.setState({ username: localStorage.getItem("USER_FIRSTNAME") });
      this.setState({ lastname: localStorage.getItem("USER_LASTNAME") });
      this.setState({ role: localStorage.getItem("ROLE") });
      this.getAccessAndEmbedToken()
      
    } else {
      const accessAPI = `/api/ADMINPORTAL/${getMsalUserInfo().username}`;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("MSAL_TOKEN"),
     };
      await Axios.get(accessAPI, { headers: headers })
        .then(res => {
          if (res.data.access.length) {
            this.getAccessAndEmbedToken();
            this.setState({
              username: res.data.profile.firstName,
              role: res.data.access[0].role.Code,
              lastname: res.data.profile.lastName
            });
            
            localStorage.setItem("USER_FIRSTNAME", res.data.profile.firstName);
            localStorage.setItem("USER_LASTNAME", res.data.profile.lastName);
            localStorage.setItem("ROLE", res.data.access[0].role.Code);
          } else {
            this.setState({ loading: false, access: false });
          }
        })
        .catch(e => {
          console.log(e);
          this.setState({ loading: false, access: false });
        });
    }
  }

  render() {
    // const { embedUrl, embedId } = Homeconfig[env];
    const embedId = process.env.REACT_APP_HOME_REPORTID.toString();
    const embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${embedId}&ctid=937d8a39-dd46-4ed8-802b-2c44da18c350&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`
    if (this.state.loading) {
      return (
        // <LoaderStyle type="ThreeDots" color="#04599C" height={80} width={80} />
        <div className="loader_style">
          <BeatLoader color="blue" size={30} loading />
        </div>
      );
    } else if (this.state.loading == false && this.state.access == false) {
      return <ErrorMessage />;
    } else {
      return (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <NavParent
            username={this.state.username}
            role={this.state.role}
            lastname={this.state.lastname}
          />
          {/* <h1 className ='header'>Home Dashboard</h1> */}
          <div style={{ flexGrow: "1" }}>
            <Report
              embedType="report"
              tokenType="Embed"
              accessToken={this.state.embedToken}
              embedUrl={embedUrl}
              embedId={embedId}
              extraSettings={{
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                hideErrors: true,
                background: models.BackgroundType.Transparent,
                ...layoutSettings
              }}
              permissions="All"
              style={{ height: window.innerHeight, width: window.innerWidth }}
              onLoad={this.handleReportLoad}
              onRender={this.handleReportRender}
            />
            <IdleTimer ref={this.timerRef} timeout={45*60*1000} onIdle={()=>getLogout()} /> 
          </div>
        </div>
      );
    }
  }
}

export default HomeDashboard;
